#tecido{
  grid-template-columns: auto auto;
  overflow-y: scroll;
  height: calc(100vh - 400px);
  margin-top: 0px;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;

  .tecido{
      border-radius: 100px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      cursor: pointer;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 40%;
      height: 100px;
      margin: 10px;
  }
}

.options{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

  button{
    margin: 5px;
  }

  div{
    display: flex;
    align-items: center;

    input{
      width: 80px !important;
      margin-left: 5px;
    }

    span{
      height: fit-content;
    }
  }
}

#fabric-options-parent, #remove-fabric{
  display: none;
}

#fabric-options{
  height: calc(100% - 300px);
}