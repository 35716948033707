.toolpanel {
  position: absolute;
  background-color: rgba(249, 249, 249, 0.9);
  width: 350px;
  height: 100%;
  border: 1px solid #ddd;
  transition: all 0.4s;
  box-sizing: border-box;
  padding: 20px;
  padding-right: 5px;
  text-align: left;
  font-size: 13px;
  color: #777;
  overflow: visible;

  .holder {
    /*overflow-x: hidden;
    overflow-y: hidden;*/
    overflow: visible;
    height: 100%;
    width: 100%;
    padding-right: 30px;
    /*overflow-y: hidden !important;*/
  }

  .minimizer {
    position: absolute;
    width: 25px;
    height: 70px;
    right: -25px;
    top: 33%;
    background-color: #f9f9f9;
    cursor: pointer;
    border: 1px solid #ddd;
    border-left: 0;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-image: url('./../icons/caret-left.svg');
    background-repeat: no-repeat;
    background-position: 5px center;
    background-size: 10px;
  }

  &.minimized {
    left: -350px;

    /*.holder {
      display: none;
    }*/

    /*.minimizer {
      background-image: url('../icons/caret-right.svg');
    }*/
  }

  &.visible{
    left: 63px !important;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid #ddd;
    color: #333;
  }

  .subtitle {
    font-size: 13px;
    text-transform: uppercase;
    color: #555;
    font-weight: bold;
    margin-bottom: 5px;
    display: inline-block;
    width: 100%;
    margin-top: 5px;
  }

  .title + p {
    margin-top: 22px;
  }


  .setting {
    width: 100%;
    clear: both;
    display: inline-block;
    margin-bottom: 10px;

    &.flex {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-row-gap: 10px;
    }

    .label,
    .function {
      width: 50%;
      float: left;
      box-sizing: border-box;
      line-height: 28px;
    }

    .label {
      padding-right: 10px;
      vertical-align: top;
      clear: left;
    }

    .function {
      padding-left: 10px;
    }
  }

  input[type="text"],
  input[type="number"] {
    background-color: #fff;
    border-radius: 6px;
    border: 2px solid #e4e4e4;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 18px;
    font-size: 13px;
    display: inline-block;
    width: inherit;
    max-width: 100%;
    min-width: auto;
  }


  .input-with-unit {
    display: table;

    input,
    span {
      display: table-cell;
    }

    input {
      width: 99%;
    }

    span {
      width: 1%;
      padding-left: 5px;
      white-space: nowrap;
      vertical-align: middle;
    }
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    border-radius: 2px;
    border: 2px solid #e4e4e4;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    top: 5px;
  }

  input[type=checkbox]:checked {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA0ElEQVQ4jZXToWqCURgG4CeIiGmIGA1Lu4CxCzB4ASK7jAXDos24tCQLuwCT2WDYJSwsjrGwNMaCyMLQ4K/8HD75z//Cad/znnPgHOrlCgvc1HSnrLHDP57Rq4NvC1xeH7m4jc+gYJ5bMAvwD7o5+BLboOAud/dlgF/RSAc7AR4GeIdBOjgujvlQKmriLcCLFF/gqzTwjQmmAd6gnxbMzxwzWtPgmp4cXlYVfkcrKoBrvFQUjM7hcsbFTile5eBjWrjHb4H/HH5h7fTwqOLF7QEbvF70RrzdLwAAAABJRU5ErkJggg==");
    background-repeat: no-repeat;
    background-size: 11px;
    background-position: center;
  }

  select {
    width: 100%;
    height: 30px;
    border: 2px solid #e4e4e4;
    border-radius: 6px;
    padding-left: 5px;
    padding-right: 20px;
    cursor: pointer;
    color: #333;
    background-image: url('../icons/caret-down.svg');
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 8px;
    appearance: none;
  }

  .small-buttons button {
    padding: 0;
    width: 32px;
    height: 32px;
  }
}

#svg-editor{
  .toolpanel{
    .subtitle{
      font-size: 18px !important;
      margin-top: 25px;
      margin-bottom: 10px;
      border-top: 1px dotted black;
    }
  }
}

.options-colors{
  margin-bottom: 10px;
  border-top: 1px solid #ddd;
}
