#annotation{
    height: 100%;
    border-top: 1px dotted;

    .annotation-expand{
        height: 30px;
        width: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        margin-top: 5px;
        
        span{
            width: 25%;
            display: block;
            margin: 0px;
            padding: 0px;
            padding-left: 10px;
            font-weight: bolder;
            font-size: 15px;
        }

        svg{
            cursor: pointer;
            transition: all 0.3s;
        }

        svg:hover{
            color: black;
        }
    }

    .annotation{
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        padding: 5px;
        transition: all 0.3s;
        background: white;
        margin-top: 3px;

        textarea{
            resize: none;
            outline: none;
        }

        input{
            outline: none;
        }

        span{
            font-weight: bolder;
            font-size: 14px;
        }
    }
}

#option-annotation, #option-text{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-top: 1px dotted;
    flex-direction: column;

    svg{
        width: 30px !important;
        height: 30px;
        cursor: pointer;
    }
}

#option-text{
    height: 300px;
    align-items: flex-start;
    text-align: left;

    span{
        width: 100%;
        margin-bottom: 5px;
    }
}