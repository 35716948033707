.background {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 5px;
  margin-top: 22px;
  overflow-y: scroll;
  height: calc(100%);

  .button {
    cursor: pointer;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    width: 32px;
    height: 32px;

    svg {
      width: 100%;
      height: auto;
      opacity: 0.5;
      max-height: 36px;
    }

    &:hover svg {
      opacity: 1;
    }
  }

  img{
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: all 0.2s;
    cursor: pointer;
    display: none;
    padding: 2px;
  }

  img:hover{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
}

.options{
    display: flex;
    text-align: center;
    border-bottom: 1px solid #ddd;

    p{
        cursor: pointer;
    }
}

#background-color{
    text-decoration: underline;
}

#uploadFileParent{
  height: 100px;
  margin-top: 20px;
  display: block;

  input{
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  div{
    height: 100px;
    margin-top: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    border: 2px dotted;
    border-radius: 10px;

    span{
      font-weight: bold;
    }
  }
}

#opacity-panel-background{
  display: none;
  flex-direction: column;
}