.file-upload{
  margin-top: 22px;

  &.no-support svg {
    opacity: 0.2;
    width: 75%;
    margin: 0 auto;
    display: block;
    margin-top: 40px;
  }

  .drop {
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    border: 2px dashed #ccc;
    border-radius: 6px;
    text-align: center;
    padding: 40px;
    margin-top: 20px;

    .info {
      color: #999;
      font-size: 14px;
      line-height: 26px;
      font-weight: bold;
    }
  }
}

#upload-object{
  text-decoration: underline;
}

#options-scene{
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 20px;

  button{
    margin-bottom: 15px;
  }
}

#transform-options{
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 5px;
}

#image-holder{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  div{
    width: 31%;
    height: 75px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 1%;
    border: 1px solid rgb(205, 205, 205);
    border-radius: 10px;
    cursor: grab;
    position: relative;
  }

  .remove_img_holder, .remove_img_back, .crop_img_holder{
    background-color: red;
    width: 20px;
    height: 20px;
    color: white;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -5px;
    top: -5px;
    cursor: pointer;
  }

  .crop_img_holder{
    background-color: green;
    right: 20px;
  }

  .remove_img_back{
    right: 20px;
    background-color: green;
  }
}