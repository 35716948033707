.sports {
  
  margin-top: 22px;
  overflow-y: scroll;
  height: 89%;//89%
  align-items: baseline;
  padding-right: 4px;
  
  .sports-scroll{
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 5px;
  }

  p{
    font-weight: bolder;
    color: black;
    font-size: 16px !important;
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  .button {
    cursor: pointer;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    width: 32px;
    height: 32px;

    svg {
      width: 100%;
      height: auto;
      opacity: 0.5;
      max-height: 36px;
    }

    &:hover svg {
      opacity: 1;
    }
  }

  img{
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: all 0.2s;
    cursor: pointer;
    display: none;
  }

  img:hover{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
}

.options{
    display: grid;
    grid-template-columns: auto auto;
    text-align: center;
    border-bottom: 1px solid #ddd;

    p{
        cursor: pointer;
    }
}

#sport-male, #anim, #custom-material{
    text-decoration: underline;
}

.sport, .anim, .pose{
    transition: all 0.4s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    border-radius: 15px;
    height: 200px;
    position: relative;
    margin-bottom: 30px;

    p{
        font-size: 13px !important;
        margin-bottom: 0px;
        text-align: center;
        position: absolute;
        bottom: -50px;
        height: 50px;
        display: flex;
        align-items: baseline;
    }
}

.sport:hover{
    border: 2px solid rgb(136, 136, 136);
}

.female, .moto, .outros, .vest, .banner, .male, .ind, .cab, .caps{
    display: none;
}

.male-anim{
  display: grid;
  grid-template-columns: auto auto;
  overflow-y: scroll;
    height: calc(100% - 100px);
    align-items: baseline;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

button{
  overflow: hidden !important;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d; 
}

#state-animation-parent{
  width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.pose{
  display: none;
}

.sport-type{
  
}

.options-change{
  display: grid !important;
  grid-template-columns: auto auto auto !important;

  p{
    margin-bottom: 5px !important;
    margin-top: 10px !important;
  }
}

.personalize-items{
  display: flex !important;
}