@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
/* general */
* {
  box-sizing: border-box;
  margin: 0px auto;
}
*:before, *:after {
  box-sizing: border-box;
}

html {
  background: #0264d6;
  background: -moz-radial-gradient(center, ellipse cover, #0264d6 1%, #1c2b5a 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(1%, #0264d6), color-stop(100%, #1c2b5a));
  background: -webkit-radial-gradient(center, ellipse cover, #0264d6 1%, #1c2b5a 100%);
  background: -o-radial-gradient(center, ellipse cover, #0264d6 1%, #1c2b5a 100%);
  background: -ms-radial-gradient(center, ellipse cover, #0264d6 1%, #1c2b5a 100%);
  background: radial-gradient(ellipse at center, #0264d6 1%, #1c2b5a 100%);
  height: calc(100vh);
  width: 100%;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.small-text {
  color: #ddd;
}

.small-text:hover {
  color: #fff;
}

p {
  line-height: 1.5em;
}

.clearfix {
  *zoom: 1;
}
.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}

.container {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

/* Login */
#login form {
  width: 250px;
}

#login,
.logo {
  display: inline-block;
  width: 40%;
}

#login {
  border-right: 1px solid #fff;
  padding: 0px 22px;
  width: 59%;
}

.logo {
  color: #fff;
  font-size: 50px;
  line-height: 125px;
}

#login form span.bi {
  background-color: #fff;
  border-radius: 3px 0px 0px 3px;
  color: #000;
  display: block;
  float: left;
  height: 50px;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}

#login > p {
  text-align: center;
}

#login > p span {
  padding-left: 5px;
}

.middle {
  display: flex;
  width: 600px;
}

#divVideo{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
}
#video{
    height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */
}

#mp4{
    
}

#back{
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #00000087;
    top: 0px;
}

#login-box span{
    width:48%; 
    text-align:left;  
    display: inline-block;
}

#login-box{
    display: flex;
    align-items: center;
}

#btn-login{
    display: flex !important;
    justify-content: flex-end;
}

.sc-beqWaB{
  position: absolute;
  z-index: 3;
}

.sc-dmqHEX div{
  background-color: white !important;
}

.sc-dmqHEX svg{
  color: black !important;
}

.bd{
  border: none !important;
  box-shadow: rgba(15, 15, 15, 0.6) 0px 2px 8px;
}

.sc-gueYoa{
  background-color: transparent !important;
  box-shadow: none !important;
}

.by, .cm{
  color: black !important;
}