html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#option-clone{
  display: flex;
  flex-direction: column;
}

#option-clone button{
  margin: 5px;
  width: 100%;
}

#back{
  background-image: url('./arte.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.lil-gui{
  opacity: 0;
}

#logo_login{
  background-image: url("./logo.png");
  position: absolute;
  top: 30px;
  right: 50px;
  z-index: 10000000;
  width: 350px;
  height: 100px;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.remove{
  display: none !important;
}

.ind, .cab, .caps{
  display: none;
}
