header {
  position: relative;
  width: 50%;
  padding: 20px;
  text-align: left;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: 0px;


  .separator {
    display: inline-block;
    margin-left: 20px;
    width: 1px;
    height: 35px;
    background-color: #ddd;
    vertical-align: middle;
  }
}
