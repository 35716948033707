.input-color {
  width: 100%;
  height: 30px;
  background-color: #e4e4e4;
  border: 2px solid #e4e4e4;
  border-radius: 6px;
  position: relative;
  cursor: pointer;

  .color {
    background-color: #fff;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=');
    background-size: 30px 30px;
    background-position: 0 0, 15px 15px;
    background-clip: content-box;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .fill {
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

.picker-holder {
  width: 100%;
  margin-top: 10px;
  display: none;

  &.visible {
    display: inline-block;
  }
}

.color-picker{
  display: block;
  width: 100%;

  input{
    width: 100%;
    border: none;
    filter: drop-shadow(2px 4px 6px black);
  }
}

#aligment-options{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 15px;
}

#content-curved-text{
  width: 100%;
}

.flex-column{
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;

  div{
    width: 100%;
  }

  label{
    margin-left: 10px;
    font-weight: bold;
  }
}

.setting-color{
  flex-direction: column !important;

  .function, .label{
    width: 100% !important;
  }

  .label{
    font-weight: bolder;
    margin-left: 10px;
    font-size: 14px;
  }
}

#option-color{
  text-decoration: underline;
}

#my-colors-svg{
  height: 527px;
  overflow-y: scroll;

  section{
    width: 100% !important;
    padding: 0px !important;
  }
}

#panel-my-colors{
  display: none;
}