@font-face {
    font-family: NeueMontreal-BoldItalic;
    src: url(fonts/NeueMontreal-BoldItalic/NeueMontreal-BoldItalic.otf);
}

@font-face {
    font-family: aAngkatanBersenjata;
    src: url(fonts/fonts/aAngkatanBersenjata.otf);
}

@font-face {
    font-family: aAlloyInk;
    src: url(fonts/fonts/aAlloyInk.otf);
}

@font-face {
    font-family: aAbstractGroovy;
    src: url(fonts/fonts/aAbstractGroovy.otf);
}

@font-face {
    font-family: aAnotherTag;
    src: url(fonts/fonts/aAnotherTag.otf);
}

.font5 {
    font-family: aAnotherTag;
}

@font-face {
    font-family: aAntaraDistance;
    src: url(fonts/fonts/aAntaraDistance.otf);
}

.font6 {
    font-family: aAntaraDistance;
}

@font-face {
    font-family: aArigatouGozaimasu;
    src: url(fonts/fonts/aArigatouGozaimasu.ttf);
}

.font7 {
    font-family: aArigatouGozaimasu;
}

@font-face {
    font-family: aAttackGraffiti;
    src: url(fonts/fonts/aAttackGraffiti.otf);
}

.font8 {
    font-family: aAttackGraffiti;
}

@font-face {
    font-family: aAutobusOmnibus;
    src: url(fonts/fonts/aAutobusOmnibus.otf);
}

.font9 {
    font-family: aAutobusOmnibus;
}

@font-face {
    font-family: Alphakind;
    src: url(fonts/fonts/Alphakind.otf);
}

.font10 {
    font-family: Alphakind;
}

@font-face {
    font-family: AromiaScript-Black_DONATIONWARE;
    src: url(fonts/fonts/AromiaScript-Black_DONATIONWARE.otf);
}

.font11 {
    font-family: AromiaScript-Black_DONATIONWARE;
}

@font-face {
    font-family: Atmos;
    src: url(fonts/fonts/Atmos.otf);
}

.font12 {
    font-family: Atmos;
}

@font-face {
    font-family: AURORA-Regular;
    src: url(fonts/fonts/AURORA-Regular.ttf);
}

.font13 {
    font-family: AURORA-Regular;
}

@font-face {
    font-family: Automobile-Contest;
    src: url(fonts/fonts/Automobile-Contest.otf);
}

.font14 {
    font-family: Automobile-Contest;
}

@font-face {
    font-family: Bekasi;
    src: url(fonts/fonts/Bekasi.otf);
}

.font15 {
    font-family: Bekasi;
}

@font-face {
    font-family: BrazierFlame;
    src: url(fonts/fonts/BrazierFlame.ttf);
}

.font16 {
    font-family: BrazierFlame;
}

@font-face {
    font-family: CastawayFill-v2;
    src: url(fonts/fonts/CastawayFill-v2.otf);
}

.font17 {
    font-family: CastawayFill-v2;
}

@font-face {
    font-family: Catamaran;
    src: url(fonts/fonts/Catamaran.ttf);
}

.font18 {
    font-family: Catamaran;
}

@font-face {
    font-family: Coconut-House;
    src: url(fonts/fonts/Coconut-House.ttf);
}

.font19 {
    font-family: Coconut-House;
}

@font-face {
    font-family: FatMax-Regular;
    src: url(fonts/fonts/FatMax-Regular.otf);
}

.font20 {
    font-family: FatMax-Regular;
}

@font-face {
    font-family: FREEZONE;
    src: url(fonts/fonts/FREEZONE.otf);
}

.font21 {
    font-family: FREEZONE;
}

@font-face {
    font-family: Grumaz-Regular;
    src: url(fonts/fonts/Grumaz-Regular.otf);
}

.font22 {
    font-family: Grumaz-Regular;
}

@font-face {
    font-family: KawitFree-CndItalic;
    src: url(fonts/fonts/KawitFree-CndItalic.ttf);
}

.font23 {
    font-family: KawitFree-CndItalic;
}

@font-face {
    font-family: Lavindir;
    src: url(fonts/fonts/Lavindir.ttf);
}

.font24 {
    font-family: Lavindir;
}

@font-face {
    font-family: LTBulletin-Medium;
    src: url(fonts/fonts/LTBulletin-Medium.ttf);
}

.font25 {
    font-family: LTBulletin-Medium;
}

@font-face {
    font-family: LullabiestByHttpsgoths-Regular;
    src: url(fonts/fonts/LullabiestByHttpsgoths-Regular.otf);
}

.font26 {
    font-family: LullabiestByHttpsgoths-Regular;
}

@font-face {
    font-family: MakerVan-Regular;
    src: url(fonts/fonts/MakerVan-Regular.ttf);
}

.font27 {
    font-family: MakerVan-Regular;
}

@font-face {
    font-family: NCS-Rogueland-Slab-Bold;
    src: url(fonts/fonts/NCS-Rogueland-Slab-Bold.ttf);
}

.font28 {
    font-family: NCS-Rogueland-Slab-Bold;
}

@font-face {
    font-family: Number9;
    src: url(fonts/fonts/Number9.ttf);
}

.font29 {
    font-family: Number9;
}

@font-face {
    font-family: PixoRetoSP-Regular;
    src: url(fonts/fonts/PixoRetoSP-Regular.otf);
}

.font30 {
    font-family: PixoRetoSP-Regular;
}

@font-face {
    font-family: Rehat;
    src: url(fonts/fonts/Rehat.otf);
}

.font31 {
    font-family: Rehat;
}

@font-face {
    font-family: Shock;
    src: url(fonts/fonts/Shock.ttf);
}

.font32 {
    font-family: Shock;
}

@font-face {
    font-family: Talisman;
    src: url(fonts/fonts/Talisman.ttf);
}

.font33 {
    font-family: Talisman;
}

@font-face {
    font-family: teefont;
    src: url(fonts/fonts/teefont.ttf);
}

.font34 {
    font-family: teefont;
}

@font-face {
    font-family: WagoonBoldInline;
    src: url(fonts/fonts/WagoonBoldInline.otf);
}

.font35 {
    font-family: WagoonBoldInline;
}

@font-face {
    font-family: BaysideFont-Regular-Demo;
    src: url(fonts/fonts/fontsFutebol/BaysideFont-Regular-Demo.otf);
}

.font36 {
    font-family: BaysideFont-Regular-Demo;
}

@font-face {
    font-family: BEST-IN-CLASS;
    src: url(fonts/fonts/fontsFutebol/BEST-IN-CLASS.ttf);
}

.font37 {
    font-family: BEST-IN-CLASS;
}

@font-face {
    font-family: CoubraFont-Demo;
    src: url(fonts/fonts/fontsFutebol/CoubraFont-Demo.otf);
}

.font38 {
    font-family: CoubraFont-Demo;
}

@font-face {
    font-family: Fut1;
    src: url(fonts/fonts/fontsFutebol/Fut1.ttf);
}

.font39 {
    font-family: Fut1;
}

@font-face {
    font-family: Fut-1;
    src: url(fonts/fonts/fontsFutebol/Fut-1.ttf);
}

.font40 {
    font-family: Fut-1;
}

@font-face {
    font-family: Fut-3;
    src: url(fonts/fonts/fontsFutebol/Fut-3.ttf);
}

.font41 {
    font-family: Fut-3;
}

@font-face {
    font-family: fut-4;
    src: url(fonts/fonts/fontsFutebol/fut-4.ttf);
}

.font42 {
    font-family: fut-4;
}

@font-face {
    font-family: fut-5;
    src: url(fonts/fonts/fontsFutebol/fut-5.ttf);
}

.font43 {
    font-family: fut-5;
}

@font-face {
    font-family: fut-6;
    src: url(fonts/fonts/fontsFutebol/fut-6.ttf);
}

.font44 {
    font-family: fut-6;
}

@font-face {
    font-family: Fut-7;
    src: url(fonts/fonts/fontsFutebol/Fut-7.ttf);
}

.font45 {
    font-family: Fut-7;
}

@font-face {
    font-family: fut-8;
    src: url(fonts/fonts/fontsFutebol/fut-8.ttf);
}

.font46 {
    font-family: fut-8;
}

@font-face {
    font-family: fut-9;
    src: url(fonts/fonts/fontsFutebol/fut-9.ttf);
}

.font47 {
    font-family: fut-9;
}

@font-face {
    font-family: fut-10;
    src: url(fonts/fonts/fontsFutebol/fut-10.ttf);
}

.font48 {
    font-family: fut-10;
}

@font-face {
    font-family: fut-11;
    src: url(fonts/fonts/fontsFutebol/fut-11.ttf);
}

.font49 {
    font-family: fut-11;
}

@font-face {
    font-family: fut-12;
    src: url(fonts/fonts/fontsFutebol/fut-12.ttf);
}

.font50 {
    font-family: fut-12;
}

@font-face {
    font-family: fut-13;
    src: url(fonts/fonts/fontsFutebol/fut-13.ttf);
}

.font51 {
    font-family: fut-13;
}

@font-face {
    font-family: fut-14;
    src: url(fonts/fonts/fontsFutebol/fut-14.ttf);
}

.font52 {
    font-family: fut-14;
}

@font-face {
    font-family: fut-15;
    src: url(fonts/fonts/fontsFutebol/fut-15.ttf);
}

.font53 {
    font-family: fut-15;
}

@font-face {
    font-family: fut-16;
    src: url(fonts/fonts/fontsFutebol/fut-16.ttf);
}

.font54 {
    font-family: fut-16;
}

@font-face {
    font-family: HighriseFont-Bold-Demo;
    src: url(fonts/fonts/fontsFutebol/HighriseFont-Bold-Demo.otf);
}

.font55 {
    font-family: HighriseFont-Bold-Demo;
}

@font-face {
    font-family: KAMIKZOM;
    src: url(fonts/fonts/fontsFutebol/KAMIKZOM.ttf);
}

.font56 {
    font-family: KAMIKZOM;
}

@font-face {
    font-family: Miamagon;
    src: url(fonts/fonts/fontsFutebol/Miamagon.otf);
}

.font57 {
    font-family: Miamagon;
}

@font-face {
    font-family: NewakeFont-Demo;
    src: url(fonts/fonts/fontsFutebol/NewakeFont-Demo.otf);
}

.font58 {
    font-family: NewakeFont-Demo;
}

@font-face {
    font-family: Russo_One;
    src: url(fonts/fonts/fontsFutebol/Russo_One.ttf);
}

.font59 {
    font-family: Russo_One;
}

@font-face {
    font-family: TESLA;
    src: url(fonts/fonts/fontsFutebol/TESLA.ttf);
}

.font60 {
    font-family: TESLA;
}

@font-face {
    font-family: urae_nium;
    src: url(fonts/fonts/fontsFutebol/urae_nium.ttf);
}

.font61 {
    font-family: urae_nium;
}

@font-face {
    font-family: Velocista-Demo-FFP;
    src: url(fonts/fonts/fontsFutebol/Velocista-Demo-FFP.ttf);
}

.font62 {
    font-family: Velocista-Demo-FFP;
}


.font1 {
    font-family: NeueMontreal-BoldItalic;
}

.font2 {
    font-family: aAngkatanBersenjata;
}

.font3 {
    font-family: aAlloyInk;
}

.font4 {
    font-family: aAbstractGroovy;
}