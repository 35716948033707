.options{
    display: flex;
    text-align: center;
    border-bottom: 1px solid #ddd;

    p{
        cursor: pointer;
    }
}

#video-auto{
    text-decoration: underline;
}

#video-panel-create{
    .options{
        display: grid;
        grid-template-columns: auto auto auto;
    }
    button{
        background: transparent;
        border: none;
        padding: 10px;
    }
    svg{
        transform: scale(0.8);
        overflow: visible;
    }
}

.panel-camera-points-card{
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    margin: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 3px;

    .options-point{
        display: flex;
        justify-content: center;

        svg{
            margin-left: 10px;
            margin-right: 10px;
            width: 22px !important;
            height: 22px !important;
            cursor: pointer;
        }
    }
}

#video-generate{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

#video-panel-create{
    display: none;
}

#video-panel-auto{
    margin-top: 10px;
}

.rotate{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.block-panel{
    width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
      flex-direction: column;

      span{
        border: 2px dotted;
        padding: 5px;
        text-align: center;
        margin: 5px;
      }
  }

  .shoe, .hair{
    transition: all 0.2s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 5px;

    p{
        font-size: 13px;
        margin-bottom: 0px;
    }

    svg{
        width: 65px;
        height: 65px;
    }
}

.expand-child{
    display: grid;
    grid-template-columns: auto auto;
    overflow-y: scroll;
      height: 300px;
}

#material{
    height: auto;
}

.material{
    pointer-events: none;
}

.expand{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 5px;
    cursor: pointer;
    display: flex;
    margin-top: 5px;
    justify-content: center;
    align-items: center;

    span{
        width: 95%;
    }
    input{
        position: inherit !important;
    }
}

.expand-child{
    height: 0px;
    transition: all 0.2s;
}

#golas, #corpo, #mask, #glasses, #fones, #polchetes, #hats{
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 10px;

    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

.input-check{
    top: 0px;
}

.glasses{
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
}

.hats{
    //swidth: 100%;
    //padding-left: 30px;
    //padding-right: 30px;
    height: auto;

    svg{
        width: 50px;
        height: 50px;
    }
}

.video-res-separator{
    width: 100%;
    text-align: center;
    border-top: 1px dotted;
    border-bottom: 1px dotted;
}

.video-options{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.link{
    color: blue;
    text-decoration: underline;
}