@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Cormorant+Garamond:wght@500&family=Dancing+Script:wght@500&family=Indie+Flower&family=Open+Sans&family=Oswald&family=Permanent+Marker&family=Playfair+Display&display=swap");

//--------------------------------------------------------------------

body,
html,
#root,
#app {
  min-height: 100%;
  top: 0px !important;
  width: 100% !important;
  position: absolute;
}

body {
  width: 100%;
  background-color: #eaeaea;
  font-family: "Open Sans", sans-serif;
  color: #333;
  line-height: 22px;
  font-size: 14px;
  user-select: none;
  position: fixed !important;
}

body * {
  box-sizing: border-box;
}

#app {
  text-align: center;
  position: relative;
}

#app,
#root {
  min-height: 100vh;
  height: 100%;
}

p {
  margin-top: 0;
}

select {
  appearance: none;
  color: #333;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #e4e4e4;
  border-radius: 6px;
  cursor: pointer;
  height: 30px;
  background-image: url("./icons/caret-down.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 6px;
  padding-left: 5px;
  padding-right: 20px;
  position: relative;
}

//--------------------------------------------------------------------

.sbar::-webkit-scrollbar {
  width: 8px;
}
.sbar {
  scrollbar-width: thin;
  scrollbar-color: #c9c9c9 #e4e4e4;
}
.sbar::-webkit-scrollbar-track {
  background: #e4e4e4;
}
.sbar::-webkit-scrollbar-thumb {
  background-color: #c9c9c9;
  border-radius: 3px;
}

//--------------------------------------------------------------------

header {
  z-index: 1;

  .menu {
    button {
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 0;
      width: 32px;
      height: 32px;
      cursor: pointer;
      border: 2px solid #000;
      border-radius: 50%;
      clear: both;
      opacity: 0.6;
      margin-left: 20px;

      &.close {
        background-color: #000;

        svg {
          fill: #fff;
        }
      }

      &:hover {
        opacity: 1;
      }

      img,
      svg {
        width: 12px;
        height: 12px;
      }

      &.disabled {
        opacity: 0.2;
      }
    }
  }

  .floating-menu {
    left: auto;
    bottom: auto;
    right: 65px;
    top: 91px;
  }
}

//--------------------------------------------------------------------

.canvas-holder {
  z-index: 0;
  overflow: hidden !important;
}

//--------------------------------------------------------------------

.toolbar {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0px;
  // height: calc(100% - 91px);
  height: 100%;
  min-height: calc(100% - 91px);
}

.toolpanel {
  // position: fixed;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: -350px;
  height: 100%;
  box-shadow: 0 0 2px 0 rgba(50, 50, 50, 0.25);

  /*&.visible {
    left: 63px;
  }
*/
  .minimizer {
    box-shadow: 1px 0 2px 0 rgba(50, 50, 50, 0.1);
  }

  button {
    background-color: #fff;
    border: 1px solid #ddd;
    text-align: center;

    &:hover {
      border-color: #555;
    }

    img,
    svg {
      opacity: 0.7;
      width: 18px;
      height: 18px;
      vertical-align: middle;

      &:hover {
        opacity: 1;
      }
    }
  }
}

//--------------------------------------------------------------------

.bottom-info {
  font-size: 13px;
  color: #999;
  position: fixed;
  right: 20px;
  bottom: 20px;
  line-height: 18px;

  img,
  svg {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 5px;
    opacity: 0.4;
  }

  select {
    width: 70px;
  }
}

//--------------------------------------------------------------------

.chrome-picker {
  background-color: transparent !important;
  box-shadow: unset !important;
  font-family: "Open Sans" !important;

  .flexbox-fix div:nth-child(2) > div:nth-child(2) {
    border: 1px solid #eee;
  }
}

//--------------------------------------------------------------------

.tabs {
  margin-top: 22px;

  .tablist {
    margin-bottom: 15px;
  }

  .tablist .button {
    font-size: 13px;

    &.active {
      color: #555;
    }
  }
}

//--------------------------------------------------------------------

#svg-editor {
  display: none;
}

#container {
  height: 100%;
  width: calc(100% - 128px);
  position: absolute;
  top: 0px;
  left: 0px;
  background: white;
  display: none;
}

#configurator {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
}

.wrap {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 20px;
}

.spinner-wrap {
  margin: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.spinner {
  height: 80px;
  width: 80px;
  display: inline-block;
  margin: -40px auto 0;
  animation: spin 1s steps(12, end) infinite;
}
.spinner:first-child {
  margin-right: 40px;
}
.spinner i {
  height: 8px;
  width: 8px;
  margin-left: -4px;
  display: block;
  transition: height 1s;
  position: absolute;
  left: 50%;
  transform-origin: center 40px;
  background: white;
  /*box-shadow: 0 0 3px rgba(107, 171, 45, 1);*/
  border-radius: 8px;
}
.spinner:nth-child(2) i {
  height: 6px;
}
.spinner:hover i {
  height: 6px;
}
.spinner:hover:nth-child(2) i {
  height: 30px;
}

.spinner i:nth-child(1) {
  opacity: 0.08;
}

.spinner i:nth-child(2) {
  transform: rotate(30deg);
  opacity: 0.167;
}

.spinner i:nth-child(3) {
  transform: rotate(60deg);
  opacity: 0.25;
}

.spinner i:nth-child(4) {
  transform: rotate(90deg);
  opacity: 0.33;
}

.spinner i:nth-child(5) {
  transform: rotate(120deg);
  opacity: 0.4167;
}

.spinner i:nth-child(6) {
  transform: rotate(150deg);
  opacity: 0.5;
}

.spinner i:nth-child(7) {
  transform: rotate(180deg);
  opacity: 0.583;
}

.spinner i:nth-child(8) {
  transform: rotate(210deg);
  opacity: 0.67;
}

.spinner i:nth-child(9) {
  transform: rotate(240deg);
  opacity: 0.75;
}

.spinner i:nth-child(10) {
  transform: rotate(270deg);
  opacity: 0.833;
}

.spinner i:nth-child(11) {
  transform: rotate(300deg);
  opacity: 0.9167;
}

.spinner i:nth-child(12) {
  transform: rotate(330deg);
  opacity: 1;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#options-scene {
  display: none;
  visibility: hidden;
}

#capture_list_container {
  display: none !important;
}

header {
  background: transparent;
}

#upperCanvas {
  pointer-events: none;
  opacity: 0.9;
}

#upperCanvasImage {
  background-size: contain;
  background-color: transparent;
  filter: invert(1);
}

.canvas-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-container {
  position: absolute !important;
}

.bottom-info,
.canvas-info {
  display: none;
}

#svg-editor-state,
#svg-editor-state-back {
  background: black;
  opacity: 1;

  svg {
    filter: invert(1);
  }
}

.canvas-holder {
  transform-origin: 0px 0px;
}

#canvas-default-size svg {
  width: 20px;
  height: 20px;
}

#holding_img {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
  display: none;
  pointer-events: none;
}

.toolpanel_panel {
  display: none;
  height: 100%;
}

.menu {
  display: flex;
  margin: 0px;
}

.menu .input-select {
  width: 200px !important;
}

.button-editor {
  width: auto !important;
  border-radius: 5px !important;
  font-weight: bold;
  opacity: 1 !important;
  padding: 5px !important;
}

.menu button {
  filter: drop-shadow(2px 4px 6px black);
  background-color: white !important;
  border: none !important;
}

#current-cloth {
  width: auto;
  padding-left: 20px;
  padding-right: 20px;

  background-color: white;
  position: absolute;
  top: 0px;
  right: 64px;
  z-index: 100;
  //width: 250px;
  height: 30px;
  box-shadow: 0 0 2px 0 rgba(50, 50, 50, 0.25);
  border-radius: 0px 0px 0px 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: bolder;
  }
}

#viewer-3d {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background: transparent;
  position: relative;
}

#externalObject {
  width: 50%;
  height: 100%;
  background: black;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  display: none;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 30px;
}

#warning {
  background-color: rgba(255, 255, 255, 0.735);
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  #warning-panel {
    background-color: white;
    width: 500px;
    height: 250px;
    border-radius: 30px;
    box-shadow: 0 0 2px 0 rgba(50, 50, 50, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
      width: 100%;
      height: 80%;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    div {
      width: 100%;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        margin: 20px;
        border: none;
        box-shadow: 0 0 2px 0 rgba(50, 50, 50, 0.25);
        transition: all 0.2s;
        background-color: white;
      }

      button:hover {
        filter: drop-shadow(2px 4px 6px black);
      }
    }
  }
}

#bottom-buttons {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;
  height: 50px;
  width: 200px;
  background-color: red;

  button {
    margin: 10px;
    border: none;
    background-color: white;
    box-shadow: 0 0 2px 0 rgba(50, 50, 50, 0.25);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
  }
}

#TextBox {
  width: 100%;
  height: 100%;

  div {
    display: flex;
    justify-content: baseline;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    flex-direction: column;

    button {
      margin: 5px;
      width: 100%;
    }
  }
}

#txt-text-editor {
  display: none;
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
}

/**-------------------------------------------------------*/

/**, *:before, *:after {
  box-sizing: border-box;
  transition: 
     background-color 400ms ease-out, 
     border-color 400ms ease-out, 
     color 400ms ease-out;
}

html, body {
  display: block; 
  height: 100%; 
}

body {
  overflow: hidden; 
  overflow-y: scroll;
  font-family: Arial, sans-serif;
  background: rgb(252,255,244);
  background: radial-gradient( ellipse at center, rgba(252,255,244,1) 0%,rgba(223,229,215,1) 50%,rgba(179,190,173,1) 100% );
}*/

#layersParent {
  height: 100%;
  z-index: 10;
  transition: all 0.2s;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  div {
    width: 100%;

    h3 {
      font-weight: bold;
      font-size: 150%;
      line-height: 1em;
    }
  }
}

ul.sortable {
  display: block;
  list-style: none;
  background: #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
  margin: 20px 0;
  padding: 5px;

  & > li {
    display: block;
    margin: 1px;
    padding: 10px;
    border-radius: 4px;
    cursor: move;
    background: #fff;

    &:hover {
      background: #ffffe0;
    }
    &.active {
      background: #ccc;
    }
    &.dragging {
      background: #e0ffff;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
      opacity: 0.5;
    }
  }
}

#clip-options {
  display: flex;
  flex-direction: column;

  button {
    margin: 3px;
  }
}

/*#btn-log-out{
  position: absolute;
  bottom: 7px;
  left: 7px;
  z-index: 10;
  transform: scale(0.6);
  color: red;
  cursor: pointer;
}*/

.panel-custom-buttons {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  justify-content: center;
  align-items: center;
  width: 63px;

  svg {
    transform: scale(0.65);
    cursor: pointer;
    margin: 10px;
    opacity: 0.7;
    height: 30px;
  }
}

#panel-save {
  justify-content: center;
  align-items: center;
  display: none;

  #panel-box-save {
    width: 400px;
    height: 200px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    filter: drop-shadow(2px 4px 6px black);

    input {
      margin-bottom: 30px;
    }

    button {
      width: 100px;
      margin: 5px;
    }
  }
}

#panel-save {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100000000;
  width: 100%;
  height: 100%;
}

.box-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  height: auto;
  position: relative;
  width: 200px;
  height: 250px;
  margin-left: 15px;
  margin-right: 15px;

  .menu-project {
    position: absolute;
    top: 15px;
    right: -9px;
    font-size: 20px;
    color: white;
    cursor: pointer;
    filter: drop-shadow(2px 4px 6px black);
  }
}

#panel-folder,
#panel-folder-clients,
#panel-search {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;

  .box-saved {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    /*margin: 10px;
    background-color: grey;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 10px;
    overflow: visible;
    position: relative;*/
  }

  .box-saved-texts {
    display: flex;
    color: black;
    padding: 10px;
    width: 100%;
    height: 60px;
    margin-top: -15px;

    span {
      margin: 0px;
      text-align: left;
      font-weight: bold;
      font-size: 15px;
    }

    div {
      display: flex;
      flex-direction: column;
      margin: 0px;
    }

    .box-saved-texts-colunm {
      width: 50%;
    }

    .box-saved-buttons {
      width: 50%;
      justify-content: center;
      align-items: end;

      svg {
        width: 15px !important;
        height: 15px;
        margin: 5px;
        transition: all 0.1s;
      }

      svg:hover {
        color: black;
        opacity: 0.7;
      }
    }
  }
}

#title-folder {
  position: absolute;
  top: 30px;
  font-weight: bolder;
  font-size: 30px;
}

#close-folder {
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bolder;
  font-size: 20px;
  border: none;
}

#storage-warning {
  color: red;
  margin-bottom: 20px;
  display: none;
}

#colorsParent {
  width: 100%;
  height: 100%;
  top: 0px;

  #colorsButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;

    button {
      margin: 5px;
    }
  }

  #colorsPanel {
    width: 100%;
    height: auto;
    display: grid;
    margin-top: 20px;
    grid-template-columns: auto auto auto auto;

    .color-svg {
      margin-bottom: 10px;

      input {
        width: 50px;
        height: 50px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.folder-tree-wrapper {
  padding-left: 0px;
  width: 100%;
  min-height: 260px;
  padding: 20px;
  margin: 35px auto;
  border-radius: 3px;
  margin-top: 0px;
  margin-bottom: 0px;

  height: calc(100% - 52px);
  overflow-y: scroll;
}
.folder-tree {
  list-style: none;
  cursor: pointer;
  padding-left: 0px;
}
.folder-tree li {
  margin-bottom: 10px;
  font-size: 14px;
  transition: all 0.4s ease;
  position: relative;
}
.folder-tree li:hover {
}
.folder-tree li i {
  color: rgb(242, 176, 53);
}
.folder-tree li ul {
  padding-left: 10px;
  padding-top: 8px;
}
.folder-tree ul {
  display: none;
  position: relative;
}
.folder-tree ul:before {
  position: absolute;
  content: "";
  left: -10px;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: transparent;
  border-left: dashed 1px #c2c2c2;
  border-bottom: dashed 1px #c2c2c2;
}
.folder-tree li ul li {
  display: block;
  margin-bottom: 8px;
}
.folder-tree .arrow {
  position: absolute;
  top: 4px;
  left: -14px;
  width: 8px;
  height: 8px;
  transition: all 0.4s ease;
  transform: rotate(-90deg);
}
.folder-tree .arrow i {
  color: #595959;
  transition: all 0.4s ease;
}
.folder-tree .arrow:hover i {
  color: #292929;
}
.folder-tree li.expanded > ul {
  display: block;
}
.folder-tree li.expanded > .arrow {
  transform: rotate(0deg);
}

.image-folder {
  width: 30px;
  height: 30px;
  margin: 0px;
  padding: 0px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;
  margin-left: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

  span {
    margin-left: 40px;
    height: 30px !important;
  }
}

#panel-graph {
  display: flex;
  width: 100%;
  justify-content: center;

  button {
    border: none;
    background-color: white;
    color: black;
    border-radius: 0px;
    opacity: 0.6;
    box-shadow: 0 0 3px 0 rgba(50, 50, 50, 0.25);
  }
}

#canvas-fabric {
  visibility: hidden;
  pointer-events: none;
}

#info-fabric {
  width: 245px;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  padding: 10px;
  height: 40%;
  flex-wrap: wrap;
  z-index: 10000;
  position: absolute;
  right: 15px;
  bottom: 0px;

  span {
    width: 220px;
    text-align: left;
    margin: 10px;
    font-size: 18px;
  }
}

.gola {
  display: none;
}

/*-------------------LOADING----------------------------*/
#loading {
  z-index: 100000000;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #292b37;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: all 0.2s;
  flex-direction: column;

  span {
    font-size: 20px;
    margin-top: -20px;
  }
}
.wrapper {
  width: 200px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}
.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.wrapper span {
  position: absolute;
  top: 80px;
  font-family: "Lato";
  font-size: 20px;
  color: #fff;
  width: max-content;
  letter-spacing: 2px;
  width: 300px;
  text-align: center;
}
/* footer  */

#my-colors {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000000;

  #my-colors-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: 20px;
    font-size: 40px;
  }

  #my-colors-options {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    #options-color {
      background-color: grey;
      height: auto;
      height: 40px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 20px;
      width: 350px;
      justify-content: center;

      #choose-rgb {
        background-color: black;
      }

      span {
        font-size: 16px;
        background-color: grey;
        color: white;
        cursor: pointer;
        height: 100% !important;
        padding: 0px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        width: 33%;
        font-weight: bold;
      }
    }

    input {
      width: 70px;
      margin-left: 5px;
      margin-right: 10px;
    }

    #options-cmk,
    #options-picker {
      display: none;
    }

    div {
      margin: 5px;
    }
  }
}

#list-colors {
  width: 100%;
  height: auto;
  margin-top: 10px;
  display: flex;
  padding: 10px;
  width: 684px;
  flex-wrap: wrap;
  align-items: baseline;

  div {
    background-color: black;
    width: 32px;
    height: 32px;
    margin: 0.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.5s;
  }
}

.holder-color {
  pointer-events: none;
}

#qr-code-panel {
  background-color: rgba(0, 0, 0, 0.95);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000000000;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  display: none;

  #qrcode {
    margin: 30px;
    overflow: hidden;
    background: white;
    padding: 20px;
    border-radius: 30px;

    img {
      width: 400px;
    }
  }

  button {
    background-color: white;
    margin: 20px;
  }

  span {
    font-size: 20px;
    margin: 5px;
  }
}

#close-my-colors,
#close-qr-panel {
  position: absolute;
  font-weight: bolder;
  top: 5px;
  right: 20px;
  font-size: 25px;
  cursor: pointer;
}

#iframe-sports {
  display: none;
}

#arscene {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  opacity: 1;
  z-index: 10000000000000;

  #arback {
    width: 80%;
    height: 90%;
    position: absolute;
  }

  #ar-info {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: white;
    text-align: center;
    font-size: 20px;
    width: 100%;
  }

  #stabilization {
    width: 400px;
  }

  input {
    transform: rotate(-90deg);
    position: absolute;
    top: 50%;
    width: 250px;
  }

  #ar-scale {
    left: -100px;
  }

  #ar-position {
    right: -100px;
  }

  #back-ar {
    position: absolute;
    left: 10px;
    bottom: 15px;
  }

  #reset-ar {
    position: absolute;
    right: 10px;
    bottom: 15px;
  }

  #ar-buttons {
    position: fixed;
    bottom: 0px;
    height: 70px;
    width: 100%;

    button {
      margin: 5px;
      border: none;
      background: white;
      filter: drop-shadow(2px 4px 6px black);
      width: 40px;
      height: 40px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ar-exp {
    visibility: hidden;
  }

  .txt-input {
    color: white;
    width: max-content;
    position: absolute;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
      0px 18px 23px rgba(0, 0, 0, 0.1);
  }

  #txt-input-scale {
    left: -40px;
    transform: rotate(-90deg);
    font-weight: bolder;
  }

  #txt-input-position {
    right: -58px;
    transform: rotate(90deg);
    font-weight: bolder;
  }
}

#ar-drag-area {
  position: absolute;
  width: 76%;
  left: 12%;
  height: 100%;
  z-index: 10000;
}

#list-iframe,
#ar-iframe {
  position: absolute;
  bottom: 20px;
  z-index: 100;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  filter: drop-shadow(2px 4px 6px black);
  background: white;
  border-radius: 50px;
}

#list-iframe {
  left: 10px;
}

#ar-iframe {
  right: 10px;
}

/*ANNOTATION*/

.annotation-3d {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin-left: 15px;
  margin-top: 15px;
  padding: 1em;
  width: 200px;
  color: #fff;
  background: transparent;
  border-radius: 0.5em;
  font-size: 12px;
  line-height: 1.2;
  transition: opacity 0.5s;
  filter: drop-shadow(2px 4px 6px black);

  p {
    display: none;
  }

  button {
    position: absolute;
    left: -35px;
    top: -30px;
    width: 30px;
    height: 30px;
    opacity: 0;
  }

  &::before {
    content: attr(data-before);
    position: absolute;
    top: -30px;
    left: -30px;
    width: 30px;
    height: 30px;
    border: 2px solid #fff;
    border-radius: 50%;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    background: rgba(0, 0, 0, 0.8);
  }
}

#number {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100px;
  display: block;
}

.toolbar-buttons {
  width: 64px;
  line-height: 0;
  border-right: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(50, 50, 50, 0.25);
  right: 0px;
  position: absolute;
  height: 100%;
  z-index: 1;

  button {
    position: relative;
    width: 100%;
    height: 54px;
    clear: both;
    border: 0;
    border-radius: unset;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      opacity: 0.7;
    }
  }

  .svg {
    display: none;
  }
}

#line-svg-button {
  height: 2px;
  background-color: black;
  width: 100%;
  opacity: 0.4;
  margin: 5px;
}

.redo {
  transform: scale(-0.65, 0.65) !important;
}

#update-graph {
  margin: 10px;
  width: max-content;
}

.toolbar-buttons {
  &.visible {
    left: 63px;
  }

  .minimizer {
  }

  button {
    &:hover {
      opacity: 1;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      box-shadow: inset 5px 0 10px 0 rgba(50, 50, 50, 0.1);
    }
  }
}

#parts-thumbs {
  width: 100%;
  height: 85%;
  overflow-y: scroll;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;

    margin-bottom: 5px;
    cursor: pointer;

    &:hover {
      box-shadow: inset 5px 0 10px 0 rgba(50, 50, 50, 0.1);
    }
  }
}

/*SHOES*/
#shoe-1 {
  background-image: url("./images/shoes/1.jpg");
}
#shoe-2 {
  background-image: url("./images/shoes/2.jpg");
}
#shoe-3 {
  background-image: url("./images/shoes/3.jpg");
}
#shoe-4 {
  background-image: url("./images/shoes/4.jpg");
}
#shoe-5 {
  background-image: url("./images/shoes/5.jpg");
}
#shoe-6 {
  background-image: url("./images/shoes/6.jpg");
}
#shoe-7 {
  background-image: url("./images/shoes/7.jpg");
}
#shoe-8 {
  background-image: url("./images/shoes/8.jpg");
}
#shoe-9 {
  background-image: url("./images/shoes/9.jpg");
}
#shoe-10 {
  background-image: url("./images/shoes/10.jpg");
}
#shoe-11 {
  background-image: url("./images/shoes/11.jpg");
}
#shoe-12 {
  background-image: url("./images/shoes/12.jpg");
}
#shoe-13 {
  background-image: url("./images/shoes/13.jpg");
}
#shoe-14 {
  background-image: url("./images/shoes/14.jpg");
}
#shoe-15 {
  background-image: url("./images/shoes/15.jpg");
}
#shoe-16 {
  background-image: url("./images/shoes/16.jpg");
}
#shoe-17 {
  background-image: url("./images/shoes/17.jpg");
}
#shoe-18 {
  background-image: url("./images/shoes/18.jpg");
}
#shoe-19 {
  background-image: url("./images/shoes/19.jpg");
}
#shoe-20 {
  background-image: url("./images/shoes/20.jpg");
}
#shoe-21 {
  background-image: url("./images/shoes/21.jpg");
}
#shoe-22 {
  background-image: url("./images/shoes/22.jpg");
}
#shoe-23 {
  background-image: url("./images/shoes/23.jpg");
}
#shoe-24 {
  background-image: url("./images/shoes/24.jpg");
}
#shoe-25 {
  background-image: url("./images/shoes/25.jpg");
}
#shoe-26 {
  background-image: url("./images/shoes/26.jpg");
}

/*MASKS*/
#mask-1 {
  background-image: url("./images/masks/1.jpg");
}
#mask-2 {
  background-image: url("./images/masks/2.jpg");
}
#mask-3 {
  background-image: url("./images/masks/3.jpg");
}
#mask-4 {
  background-image: url("./images/masks/4.jpg");
}
#mask-5 {
  background-image: url("./images/masks/5.jpg");
}
#mask-6 {
  background-image: url("./images/masks/6.jpg");
}
#mask-7 {
  background-image: url("./images/masks/7.jpg");
}
#mask-8 {
  background-image: url("./images/masks/8.jpg");
}
#mask-9 {
  background-image: url("./images/masks/9.jpg");
}
#mask-10 {
  background-image: url("./images/masks/10.jpg");
}
#mask-11 {
  background-image: url("./images/masks/11.jpg");
}
#mask-12 {
  background-image: url("./images/masks/12.jpg");
}
#mask-13 {
  background-image: url("./images/masks/13.jpg");
}
#mask-14 {
  background-image: url("./images/masks/14.jpg");
}
#mask-15 {
  background-image: url("./images/masks/15.jpg");
}
#mask-16 {
  background-image: url("./images/masks/16.jpg");
}

/*HAIRS*/
#hair-1 {
  background-image: url("./images/hair/1.jpg");
}
#hair-2 {
  background-image: url("./images/hair/2.jpg");
}
#hair-4 {
  background-image: url("./images/hair/4.jpg");
}
#hair-5 {
  background-image: url("./images/hair/5.jpg");
}
#hair-6 {
  background-image: url("./images/hair/6.jpg");
}
#hair-7 {
  background-image: url("./images/hair/7.jpg");
}
#hair-9 {
  background-image: url("./images/hair/9.jpg");
}
#hair-10 {
  background-image: url("./images/hair/10.jpg");
}

/*GLASSES*/
#glass-1 {
  background-image: url("./images/glasses/1.jpg");
}
#glass-2 {
  background-image: url("./images/glasses/2.jpg");
}
#glass-3 {
  background-image: url("./images/glasses/3.jpg");
}
#glass-4 {
  background-image: url("./images/glasses/4.jpg");
}
#glass-5 {
  background-image: url("./images/glasses/5.jpg");
}
#glass-6 {
  background-image: url("./images/glasses/6.jpg");
}
#glass-7 {
  background-image: url("./images/glasses/7.jpg");
}
#glass-8 {
  background-image: url("./images/glasses/8.jpg");
}

/*HATS*/
#hat-1 {
  background-image: url("./images/hats/1.jpg");
}
#hat-2 {
  background-image: url("./images/hats/2.jpg");
}
#hat-4 {
  background-image: url("./images/hats/4.jpg");
}
#hat-5 {
  background-image: url("./images/hats/5.jpg");
}

/*MALE SPORTS*/
#soccer-male-1 {
  background-image: url("./images/male/soccer/1.jpg");
  background-size: contain;
  /*width: 95% !important;
  height: 220px !important;*/
}
#soccer-male-2 {
  background-image: url("./images/male/soccer/2.jpg");
  background-size: contain;
}
#soccer-male-3 {
  background-image: url("./images/male/soccer/3.jpg");
  background-size: contain;
}
#soccer-male-4 {
  background-image: url("./images/male/soccer/4.jpg");
  background-size: contain;
}
#soccer-male-5 {
  background-image: url("./images/male/soccer/5.jpg");
  background-size: contain;
}
#soccer-male-6 {
  background-image: url("./images/male/soccer/6.jpg");
  background-size: contain;
}

#parts-panel {
  display: none;
}

/*PANEL DEFAULT*/
.panel-default {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: 10000;
  background-color: white;
  padding: 10px;
  display: none;
  flex-direction: column;
  padding-bottom: 0px;

  .panel-default-header {
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;

    #panel-title {
      width: 40%;
      text-align: left;
      padding: 0px;
      margin: 0px;
      font-size: 40px;
      font-weight: bolder;
      opacity: 0.8;
      line-height: 20pt;
    }

    div {
      width: 70%;
      display: flex;
      justify-content: flex-end;
      overflow: visible;

      button {
        margin: 15px;
        background-color: #fff;
        border: 1px solid #ddd;
        text-align: center;
        font-weight: bold;
        opacity: 0.8;
        transition: 0.1s;
        margin-top: 4px;
        margin-bottom: 4px;

        &:hover {
          box-shadow: inset 5px 0 10px 0 rgba(50, 50, 50, 0.1);
        }
      }
    }
  }

  .panel-default-body {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    #lista-pedidos {
      margin: 20px;
      height: 300px;
      width: 400px;
      overflow-y: hidden;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 18px;
        height: 30px;

        span {
          text-align: left;
          width: 30%;
          font-weight: bolder;
          opacity: 0.7;
          font-size: 20px;
        }

        input {
          width: 70%;
          height: 100%;
        }

        button {
          margin: 15px;
          background-color: #fff;
          border: 1px solid #ddd;
          text-align: center;
          font-weight: bold;
          opacity: 0.8;
          transition: 0.1s;
        }
      }
    }
  }
}

.panel {
  display: none;
  overflow-y: hidden;
}

.panel-default-body .panel {
  width: 100%;
}

#lista-geral {
  flex-direction: column;
  justify-content: flex-start !important;
  width: 800px;
  align-items: flex-start;
  overflow-y: scroll;
  height: calc(100% - 150px);

  .list-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      margin: 0px;
    }

    input {
      margin: 5px;
      width: 95%;
    }
  }

  div {
    width: 100%;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-weight: bold;
      opacity: 0.7;
      font-size: 18px;
    }

    svg {
      color: red;
      transform: scale(1.1);
      margin-right: 5px;
      cursor: pointer;
    }
  }
}

.panel {
  button {
    margin: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    text-align: center;
    font-weight: bold;
    opacity: 0.8;
    transition: 0.1s;
  }
}

#patterns {
  height: calc(100% - 160px);
  display: grid;
  grid-template-columns: auto auto;
  align-content: baseline;
  overflow-y: scroll;

  div {
    height: 100px;
    background-size: contain;
    margin: 5px;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: inset 5px 0 10px 0 rgba(50, 50, 50, 0.1);
    cursor: pointer;
    padding: 3px;
  }
}

#options-patterns {
  display: flex;
  flex-wrap: wrap;

  span {
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    margin: 5px;
    cursor: pointer;
  }
}

#fabric-options-parent {
  height: calc(100% - 230px);
}

#arrow-canvas-left {
  display: none;
}

#expand-canvas {
  background-color: black;
  color: white;

  svg {
    opacity: 1;
  }
}

#update-canvas-3d {
  svg {
    transform: scale(9);
  }
}

#configurator {
  .toolpanel {
    background-color: rgba(255, 255, 255, 0.8) !important;
  }
}

.btn-iframe {
  font-size: 18px;
}

#video-points {
  overflow-y: scroll;
  height: calc(100vh - 220px);
}

.modal-menu-options {
  position: absolute;
  top: 18px;
  right: 0px;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: baseline;
  justify-content: left;
  filter: drop-shadow(2px 4px 6px black);
  overflow: hidden;
  opacity: 0;
  transition: all 0.2s;
  pointer-events: none;

  span {
    width: 100%;
    text-align: left;
    font-weight: bolder;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgb(240, 240, 240);
    margin: 1px;
    transition: all 0.2s;
  }

  span:hover {
    background-color: black;
    color: white;
  }
}

#btn-edit-cache {
  opacity: 0.5;
  pointer-events: none;
}

#modal-text-iframe {
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 10000000;

  .box-modal-text-iframe {
    background: white;
    height: 120px;
    width: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    filter: drop-shadow(2px 4px 6px black);
    border-radius: 10px;

    input {
      margin: 5px;
    }

    .box-buttons-modal-text-iframe {
      margin: 5px;
      display: flex;

      button {
        margin: 5px;
        background-color: black;
        color: white;
      }
    }
  }
}

.sport {
  background-size: cover;
  width: 95%;
  height: 100px;
  background-position: center;
}

//

#loadingNew {
  position: relative;
  width: 70px;
  height: 34px;
  margin: 50px auto;
  transform: scale(1.3);
}

$time: 1.5s;
%path {
  offset-path: path(
    "M67.3,17.8c0-9.3-4.8-14.5-13.8-14.5C41.9,3.2,35.7,17,35.7,17s-6.2,13.8-17.8,13.8C10,30.8,4,25.5,4,17.7 C4, 9.6, 10, 3.2, 17.8, 3.2C29.4, 3.2, 35.7, 17, 35.7, 17s6.2, 13.8, 17.8, 13.8C61.4, 30.8, 67.3, 24.8, 67.3, 17.8z"
  );
  position: absolute;
}

#loadingNew p {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  opacity: 0;
  motion-rotation: auto 45deg; //reverse
  background: #fff;
  animation: path 1.4s linear infinite, dot linear forwards;
  @extend %path;
  @for $i from 1 through 35 {
    &:nth-of-type(#{$i}) {
      background: hsl(($i - 1) * 10deg, 50%, 50%);
      animation-delay: $time/100 * $i;
    }
  }
}

@keyframes path {
  to {
    offset-distance: 100%;
  }
}

@keyframes dot {
  to {
    opacity: 1;
  }
}

@supports not (offset-rotate: 0deg) {
  body:before {
    content: "Sorry! you browser not supports!";
    background: #dc3b3b;
    padding: 10px 0;
    text-align: center;
    display: block;
    color: #fff;
  }
}

//

:root {
  --cover-timing: 0.5s;
  --cover-ease: cubic-bezier(0.66, 0.08, 0.19, 0.97);
  --cover-stagger: 0.15s;
  --text-timing: 0.75s;
  --text-stagger: 0.015s;
  --text-ease: cubic-bezier(0.38, 0.26, 0.05, 1.07);
  --title-stagger: 0.05s;
  --highlight: -webkit-linear-gradient(
    130deg,
    rgba(255, 255, 255, 1),
    rgba(255, 127, 80, 1)
  );
}
.card {
  position: relative;
  overflow: hidden;
  aspect-ratio: 0.75;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  //box-shadow: rgba(255, 255, 255, 0.3) 0 5vw 6vw -8vw, rgba(255, 255, 255, 0) 0 4.5vw 5vw -6vw, rgba(50, 50, 80, 0.5) 0px 4vw 8vw -2vw, rgba(0, 0, 0, 0.8) 0px 4vw 5vw -3vw;
  transition: box-shadow 1s var(--cover-ease);
  margin: 5px;
  filter: drop-shadow(2px 4px 6px black);
}
.card > * {
  z-index: 2;
}
.card h2,
.card p {
  background: -webkit-linear-gradient(
    130deg,
    rgba(255, 255, 255, 1),
    rgba(255, 127, 80, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.97, 0.8, 0.19, 0.97);
}
.card > img {
  z-index: 0;
  transition: all 0.8s cubic-bezier(0.66, 0.08, 0.19, 0.97);
}
.card::before,
.card::after {
  content: "";
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  transform-origin: top left;
  transform: scaleY(0) rotateY(-45deg);
  transition: all var(--cover-timing) var(--cover-ease);
  z-index: 1;
}
.card::after {
  transition-delay: var(--cover-stagger);
  transform: scaleY(0) rotateY(45deg);
  right: 0%;
  top: 50%;
  transform-origin: bottom right;
}
.card:hover {
  box-shadow: white 0 5vw 6vw -9vw, var(--highlight) 0 5.5vw 5vw -7.5vw,
    rgba(50, 50, 80, 0.5) 0px 4vw 8vw -2vw, rgba(0, 0, 0, 0.8) 0px 4vw 5vw -3vw;
}
.card:hover::before,
.card:hover::after {
  transform: scaleY(1) rotateX(0deg);
}
.card:hover h2,
.card:hover p,
.card:hover .badge-parent {
  opacity: 1;
  transform: translateY(0) rotateX(0deg);
  color: inherit;
  transition: all var(--cover-timing) var(--cover-ease);
  background: white;
  color: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 1;
}

.card:hover img {
  transform: scale(1.1);
}
.card:nth-of-type(1) {
  --highlight: coral;
}

.text {
  position: absolute;
  inset: 20px;
  top: auto;

  h2 {
    font-size: 30px;
    font-size: clamp(20px, 4vw, 40px);
    font-weight: 800;
    margin-bottom: 0.2em;
    padding: 15px;
    line-height: 40px;
  }
  p {
    font-size: 12px;
    font-size: clamp(10px, 1.25vw, 14px);
    line-height: 1.4;
    text-align: justify;
    margin-top: 0.2em;
    margin-bottom: 0;
  }
}

.badge-parent {
  height: 75px;
  width: 100%;
  display: flex;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.badge {
  color: #ffffff;
  padding: 0.5rem;
  margin: 1rem;
  border-radius: 0.4rem;
  margin-right: auto;
  font-weight: 700;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 30px;
  }
}
/*img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
}*/
main {
  grid-template-columns: 1fr;
  grid-template-rows: 60px;
  grid-gap: 2em;
}

.card {
  width: 90vw;
  max-width: 300px;
}

h1 {
  color: #5b6377;
  font-weight: 100;
}

.search-bar {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: auto !important;
  margin: 0px;
  width: 500px !important;

  input {
    height: 30px;
  }
}

#box-options-projects {
  flex-wrap: wrap;
}

#custom-banner {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  z-index: 10000000000;
  top: 0px;
  left: 0px;
  display: none;
  align-items: center;
  justify-content: center;

  #custom-banner-box {
    background-color: white;
    filter: drop-shadow(2px 4px 6px black);
    height: 250px;
    width: 400px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;

    #custom-banner-box-input {
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    #custom-banner-box-buttons {
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      button {
        margin: 10px;
      }
    }
  }
}

#state-wind {
  display: none;
}

#panel-crop {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: white;
  display: none;
  z-index: 100000000000;

  .image_container {
    width: 50%;
    height: 100%;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: contain;
      position: relative;
    }

    .cropper-crop-box {
      margin: 0px;
    }
  }

  #cropped_result {
    width: 50%;
    height: 100%;
    margin: 0px;
  }

  #crop-buttons {
    position: absolute;
    z-index: 100000;
    top: 10px;
    right: 10px;
    display: flex;

    button {
      background-color: white;
      border-radius: 50px;
      border: none;
      width: 50px;
      height: 50px;
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(2px 4px 6px black);

      svg {
        font-size: 20px;
      }
    }
  }
}

#cropped_result {
  display: flex;
}

#my-projects-pages {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .active {
    background-color: grey;
    color: white;
  }

  span {
    height: 30px;
    width: 30px;
    margin: 5px;
    cursor: pointer;
    background-color: white;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    box-shadow: 0 0 3px 0 rgba(50, 50, 50, 0.5);
    font-weight: bold;
  }

  span:hover {
    background-color: black;
    color: white;
  }
}

#google_translate_element {
  position: absolute;
  z-index: 100000000;
  bottom: 0px;
}

.skiptranslate {
  iframe {
    visibility: hidden !important;
  }
}

#google_translate_element {
  visibility: hidden;
}

//
.ct-topbar {
  text-align: right;
  background: #eee;
}
.ct-topbar__list {
  margin-bottom: 0px;
}
.ct-language__dropdown {
  padding-top: 8px;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  top: 110%;
  left: -3px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  width: 100px;
  text-align: center;
  padding-top: 0;
  z-index: 200;
}
.ct-language__dropdown li {
  background: #222;
  padding: 5px;
}
.ct-language__dropdown li a {
  display: block;
}
.ct-language__dropdown li:first-child {
  padding-top: 10px;
  border-radius: 3px 3px 0 0;
}
.ct-language__dropdown li:last-child {
  padding-bottom: 10px;
  border-radius: 0 0 3px 3px;
}
.ct-language__dropdown li:hover {
  background: #444;
}
.ct-language__dropdown:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 0;
  border: 0 solid transparent;
  border-right-width: 8px;
  border-left-width: 8px;
  border-bottom: 8px solid #222;
}
.ct-language {
  position: relative;
  background: #00aced;
  color: #fff;
  padding: 10px 0;
}
.ct-language:hover .ct-language__dropdown {
  max-height: 200px;
  padding-top: 8px;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  visibility: hidden;
}

#panel-search {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background: #ffffffb8;
  padding-top: 50px;

  #close-search-panel {
    position: fixed;
    top: 10px;
    right: 10px;
    margin: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    text-align: center;
    font-weight: bold;
    opacity: 0.8;
    transition: 0.1s;
    margin-top: 4px;
    margin-bottom: 4px;
    font-weight: bolder;

    font {
      height: auto;
    }
  }

  .card {
    height: 400px;
  }
}

#tutorial-panel {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000000000000000000;
  background-color: white;
  width: 100%;
  height: 100%;
  display: none;

  .active {
    font-weight: bolder;
  }

  #tutorial-header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    #title-tutorial {
      width: 90%;
      font-size: 40px;
      font-weight: bolder;
      opacity: 0.8;
      text-align: left;
      padding: 0px;
      margin: 0px;
      padding-left: 20px;
    }

    #close-tutorial {
      width: 10%;
      margin-right: 2%;
      display: flex;
      justify-content: flex-end;
    }
  }

  #tutorial-body {
    width: 100%;
    height: 95%;
    margin-top: 5%;
    display: flex;
    filter: drop-shadow(2px 4px 6px black);
    background: white;

    #tutorial-left {
      width: 20%;
      display: flex;
      flex-direction: column;
      height: 90%;
      padding-left: 20px;
      padding-right: 20px;
      overflow-y: scroll;
      margin-top: 15px;

      span {
        text-align: left;
        width: 100%;
        cursor: pointer;
        font-size: 18px;
        margin-bottom: 10px;
      }

      span:hover {
        text-decoration: underline;
      }
    }

    #tutorial-right {
      width: 80%;
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      #tutorial-video {
        width: 100%;
        height: 70%;

        iframe {
          width: 95%;
          height: 100%;
        }
      }

      #tutorial-info {
        width: 95%;
        height: 30%;
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin-top: 20px;
        font-size: 18px;
        text-align: justify;
      }
    }
  }
}

.icon-svg {
  font-size: 20px;
}

#background-panel {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: cover !important;
  background-position: center !important;
}

#qrcode-link {
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

#obj-options-parent {
  width: 200px;
  height: 420px;
  position: absolute;
  z-index: 1;
  top: -390px;
  right: 64px;
  background-color: white;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 10px;
  border-radius: 0px 0px 0px 40px;
  transition: all 0.3s;
  display: none;

  span {
    text-align: left;
  }

  #expand-obj-options {
    position: absolute;
    bottom: -30px;
    width: 50px;
    height: 30px;
    border: none;
    background-color: white;
    border-radius: 0px 0px 10px 10px;

    svg {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.obj-options-opened {
  top: 30px !important;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,100;1,200;1,300&display=swap");

.login-page {
  height: 100vh;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 10;
  position: absolute;
  display: flex;
  justify-content: flex-start;

  .form {
    position: relative;
    filter: drop-shadow(0 0 2px #000000);
    border-radius: 5px;
    width: 360px;
    height: 420px;
    background-color: #ffffff;
    padding: 40px;
    margin: 10%;
  }

  .form img {
    position: absolute;
    height: 20px;
    top: 230px;
    right: 60px;
    cursor: pointer;
  }

  .form input {
    outline: 0;
    background: #f2f2f2;
    border-radius: 4px;
    width: 100%;
    border: 0;
    margin: 15px 0;
    padding: 15px;
    font-size: 14px;
  }

  .form input:focus {
    box-shadow: 0 0 5px 0 rgba(106, 98, 210);
  }

  span {
    color: red;
    margin: 10px 0;
    font-size: 14px;
  }

  .form button {
    outline: 0;
    background: #6a62d2;
    width: 100%;
    border: 0;
    margin-top: 10px;
    border-radius: 3px;
    padding: 15px;
    color: #ffffff;
    font-size: 15px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
  }

  .form button:hover,
  .form button:active,
  .form button:focus {
    background: black;
    color: #fff;
  }

  .message {
    margin: 15px 0;
    text-align: center;
  }
  .form .message a {
    font-size: 14px;
    color: #6a62d2;
    text-decoration: none;
  }
}

/*---------------------*/
#options-lista {
  width: 100%;
  height: 100%;
  display: none;
}
.scene-up {
  width: 100%;
  height: 150px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.25s;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.scene-up:hover {
  filter: drop-shadow(2px 4px 6px black);
}
#scene1 {
  background-image: url("./images/scenes/scene1.png");
}
#scene2 {
  background-image: url("./images/scenes/scene2.png");
}
#scene3 {
  background-image: url("./images/scenes/scene3.png");
}
#scene4 {
  background-image: url("./images/scenes/scene4.png");
}
#scene5 {
  background-image: url("./images/scenes/scene5.png");
}
#scene6 {
  background-image: url("./images/scenes/scene6.png");
}
#scene7 {
  background-image: url("./images/scenes/scene7.png");
}
#scene8 {
  background-image: url("./images/scenes/scene8.png");
}
#scene9 {
  background-image: url("./images/scenes/scene9.png");
}
#scene10 {
  background-image: url("./images/scenes/scene10.png");
}
#scene11 {
  background-image: url("./images/scenes/scene11.png");
}
#scene12 {
  background-image: url("./images/scenes/scene12.png");
}

#panel-stats {
  width: 400px;
  height: auto;
  max-height: 80%;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  right: 64px;
  bottom: 0px;
  filter: drop-shadow(2px 4px 6px black);

  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;

  div {
    margin-bottom: 2px;
  }
}

#obj-options-parent, #options-scene, #download-vistas {
  display: none !important;
}

#btn-general-Options {
  position: absolute;
  bottom: 0px;
  font-size: 25px;
}

#panel-categories{
  background: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10000;
  display: none;
  flex-direction: column;

  #panel-categories-header{
    width: 100%;
    height: 7%;
    display: flex;
    justify-content: end;
    align-items: center;

    button{
      margin: 10px;
      transition: all 0.3s;
      font-weight: bolder;
      border: 2px solid black;
      background-color: white;
    }

    button:hover{
      background-color: black;
      color: white;
    }
  }

  #panel-categories-body{
    width: 100%;
    height: 93%;
    display: flex;
    justify-content: center;
    align-items: center;

    #block-categories{
      width: 30%;
      height: 90%;
      border-radius: 20px;
      filter: drop-shadow(2px 4px 6px black);
      background-color: white;
    }

    #block-objects{
      width: 30%;
      height: 90%;
      border-radius: 20px;
      filter: drop-shadow(2px 4px 6px black);
      background-color: white;
    }

    #block-cliparts{
      width: 30%;
      height: 90%;
      border-radius: 20px;
      filter: drop-shadow(2px 4px 6px black);
      background-color: white;
    }
  }
}