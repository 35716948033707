.downloads {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  div{
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    cursor: pointer;
    border-radius: 50px;
    margin: 5px;
    box-shadow: 0 0 2px 0 rgba(50, 50, 50, 0.25);
    height: 100px;
    background-color: white;
    transition: 0.2s;
    text-align: center;
    font-size: 13px;
  }

  div:hover{
    filter: drop-shadow(2px 4px 6px black);
  }
}

.download-separator{
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  div{
    border: 1px dotted black;
    width: 100%;
  }

  p{
    margin-bottom: 0px;
  }
}